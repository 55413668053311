import * as React from 'react'
import { Link, graphql, type HeadFC } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import { FontAwesomeIcon, solidIcons } from '../components/icons'
const { faArrowRight } = solidIcons

const AboutPage = () => {
  return (
    <Layout>
      <section className="flex min-h-screen flex-col px-4 py-8">
        <div className="sm:container sm:mx-auto">
          <h1 className="mb-8 text-3xl font-bold text-gray-800 sm:text-5xl">
            Sobre Nosotros
          </h1>
          <p className="mb-12 text-xl text-gray-500 sm:text-2xl">
            Predicamos a Cristo crucificado y resucitado para nuestra
            justificación.
          </p>
          <section className="flex flex-col lg:flex-row">
            <div className="lg:flex-1">
              <h2 className="mb-8 text-2xl font-bold text-gray-800 md:text-4xl">
                ¿Quiénes Somos?
              </h2>
            </div>
            <div className="md:text-xl lg:flex-1">
              <p className="mb-8 text-slate-800">
                Somos una iglesia cristiana evangélica que se congrega en la
                ciudad de Madrid (España), en el céntrico barrio de Chamberí.
                Nuestra misión es preparar a los creyentes para que vivan con
                gozo vidas santas, para que se sirvan unos a otros, y para que
                compartan el evangelio de Jesucristo, todo para la gloria de
                Dios.
              </p>
              <p className="mb-8 text-slate-800">
                Pertenecemos a las Asambleas de Hermanos, nuestros cultos se
                centran en la predicación de la Palabra de Dios, a lo que se
                suman tiempos de alabanza al Señor. Puedes conocer más sobre lo
                que creemos y lo que enseñamos en nuestra base doctrinal.
              </p>
              <Link to="/beliefs">
                <span className="mr-2 text-amber-500">Lo que creemos</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-amber-500"
                />
              </Link>
            </div>
          </section>
          <section className="my-10 flex flex-col lg:flex-row lg:gap-x-4">
            <StaticImage
              alt="Iglesia Evangelica de Chamberi, puerta principal"
              src="../images/iglesia-1.jpeg"
              className="mb-4 rounded-md"
            />
            <StaticImage
              alt="Antonio, llevando la Predicacion de la Palabra de Dios"
              src="../images/iglesia-2.jpeg"
              className="mb-4 rounded-md"
            />
            <StaticImage
              alt="Mariano, tocando el piano para alabanza del Señor"
              src="../images/iglesia-3.jpeg"
              className="mb-4 rounded-md"
            />
          </section>
          <section className="max-md mx-auto mb-12 rounded-2xl bg-gradient-to-r from-amber-500 via-orange-500 to-amber-500 px-4 py-8 text-center text-gray-100">
            <h2 className="mb-4 text-2xl font-bold md:mb-8 md:text-3xl">
              Desde 1874 hasta nuestros días
            </h2>
            <p className="mx-auto max-w-2xl text-lg md:text-2xl">
              Conoce la historia de cómo los pioneros de las Asambleas de
              Hermanos extendieron el evangelio en la ciudad de Madrid.
            </p>
            <Link to="/history">
              <button className="mt-8 rounded-2xl bg-gray-800 p-6 text-lg md:mt-12 md:text-2xl">
                Nuestra Historia
              </button>
            </Link>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export const Head: HeadFC = () => <title>About Me</title>
export default AboutPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
